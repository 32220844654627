<template>
    <div class="pa-6">
        <div class="d-flex align-center justify-space-around" style="column-gap: 15px;">
            <v-text-field class="mt-4" prepend-inner-icon="mdi-magnify" variant="solo"
                :label="$t('Enter Attendee Name...')" v-model="nameSearchValue" @keyup.enter="searchFilter" />
            <v-text-field class="mt-4" prepend-inner-icon="mdi-phone" variant="solo"
                :label="$t('Enter Attendee Phone Number...')" v-model="phoneSearchValue" @keyup.enter="searchFilter" />
            <v-text-field class="mt-4" prepend-inner-icon="mdi-email" variant="solo"
                :label="$t('Enter Attendee Email...')" v-model="emailSearchValue" @keyup.enter="searchFilter" />
            <div class="d-flex flex-column" style="row-gap: 10px;">
                <v-btn class="font-weight-bold" color="info" @click="searchFilter">{{
                    $t('Search') }}</v-btn>
                <v-btn class="font-weight-bold" :disabled="!nameSearchValue && !phoneSearchValue && !emailSearchValue" color="red"
                    @click="resetFilter">{{ $t('Reset') }}</v-btn>
                <v-btn disabled :loading="excelLoading" class="font-weight-bold" color="green" @click="downloadExcel">{{ $t('Download Excel') }}</v-btn>
            </div>
        </div>
        <div class="d-flex align-center justify-space-around my-5 flex-column flex-md-row"
            :style="$vuetify.display.mobile ? 'row-gap: 10px' : ''">
            <v-btn :size="$vuetify.display.mobile ? 'small' : 'default'" :disabled="page == 1" @click="fetchPage(-1)"
                :elevation="0" icon>
                <v-icon>{{ $i18n.locale === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
            </v-btn>

            <div>
                <div class="d-flex" style="column-gap: 6px;">
                    <span style="font-weight: 700;">{{ $t('Total Attendees') }}:</span>
                    <span>{{ length }}</span>
                </div>
                <div class="d-flex" style="column-gap: 6px;">
                    <span style="font-weight: 700;">{{ $t('Total Pages') }}:</span>
                    <span>{{ attendeesTotalPages }}</span>
                </div>
            </div>

            <div>
                <div class="d-flex" style="column-gap: 6px;">
                    <span style="font-weight: 700;">{{ $t('Page') }}:</span>
                    <span>{{ page }}</span>
                </div>
            </div>

            <v-btn :size="$vuetify.display.mobile ? 'small' : 'default'" :disabled="page == attendeesTotalPages"
                @click="fetchPage(1)" :elevation="0" icon>
                <v-icon>{{ $i18n.locale === 'ar' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
            </v-btn>
        </div>
        <v-data-table :items="attendees" :headers="headers" :loading="attendeesLoading" dense
            :items-per-page="pageSize">
            <template #bottom>
                <div class="d-flex align-center justify-space-around mt-5">
                    <v-btn :size="$vuetify.display.mobile ? 'small' : 'default'" :disabled="page == 1"
                        @click="fetchPage(-1)" :elevation="0" icon>
                        <v-icon>{{ $i18n.locale === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                    </v-btn>

                    <v-btn :size="$vuetify.display.mobile ? 'small' : 'default'" :disabled="page == attendeesTotalPages"
                        @click="fetchPage(1)" :elevation="0" icon>
                        <v-icon>{{ $i18n.locale === 'ar' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
                    </v-btn>
                </div>
            </template>

            <template v-slot:[`item.ticketStatus`]="{ item }">
                <div class="d-flex align-center justify-center" style="column-gap: 15px;">
                    <div class="d-flex flex-column align-center" v-for="type in sendingTypes" :key="type.id">
                        <v-tooltip open-delay="200" location="top" :text="$filters.get_name(type)">
                            <template v-slot:activator="{ props }">
                                <div v-bind="props" style="width: 85%;">
                                    <v-icon
                                        :color="getTicketStatus(type.name_english, item.error_logs) ? 'green' : 'red'">{{
                    type.icon }}</v-icon>
                                </div>
                            </template>
                        </v-tooltip>

                    </div>
                </div>
            </template>

            <template v-slot:[`item.Event.name`]="{ item }">
                <div class="d-flex align-center flex-column my-2" style="row-gap: 6px">
                    <div
                        style="max-width: 100px;width: 100%; max-height: 100px;height: 100%;margin: 4px;padding: 1px;border: 1px solid gray;border-radius: 5px;">
                        <v-img :src="item.Event.logo ? item.Event.logo : require('../assets/default-event-photo.png')"
                            cover style="height: 100%;width: 100%;" />
                    </div>
                    <span>{{ $filters.get_name(item.Event) }}</span>
                </div>
            </template>

            <template v-slot:[`item.lastCheckIn`]="{ item }">
                <div class="d-flex align-center flex-column" style="row-gap: 15px">
                    <div v-if="item.CheckIns.length">
                        <div class="d-flex" style="column-gap: 5px;">
                            <span class="font-weight-bold">{{ $t('Date and Time') }}:</span>
                            {{ $filters.get_date_time(item.CheckIns[0].createdAt) }}
                        </div>
                        <div class="d-flex" style="column-gap: 5px;">
                            <span class="font-weight-bold">{{ $t('Event Name') }}:</span>
                            {{ $filters.get_name(item.CheckIns[0].EventDate) }}
                        </div>
                        <div class="d-flex" style="column-gap: 5px;">
                            <span class="font-weight-bold">{{ $t('Volunteer Name') }}:</span>
                            {{ item.CheckIns[0].CreatedBy.name }}
                        </div>
                    </div>
                    <span v-else>{{ $t('No Check-Ins') }}</span>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
    name: 'AttendeesPerEventDateTable',
    props: {

    },
    data() {
    return {
      event_date_id: this.$route.params.event_date_id,
        attendeesLoading: false,
        excelLoading: false,
        attendees: [],
        page: 1,
        pageSize: 100,
        length: 0,
        nameFilter: null,
        attendeesTotalCount: null,
        attendeesTotalPages: null,
        sendingTypes: [],
        nameSearchValue: null,
        phoneSearchValue: null,
        emailSearchValue: null
    };
  },
    async created() {
        await this.getSnedingTypes();
        await this.getAttendees();
    },
    computed: {
        headers() {
            return [
                {
                    title: this.$t('Name'),
                    key: 'name',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Email'),
                    key: 'email',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Phone Number'),
                    key: 'phone',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Main Event Name'),
                    key: 'Event.name',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Ticket Status'),
                    key: 'ticketStatus',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Last Check-In'),
                    key: 'lastCheckIn',
                    align: 'center',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        async getAttendees() {
            this.attendeesLoading = true;
            await this.$axios.get('/attendees', {
                params: {
                    page: this.page,
                    pageSize: this.pageSize,
                    name: this.nameFilter,
                    phone: this.phoneFilter,
                    email: this.emailFilter,
                    includeEvent: true,
                    groupByAttendee: true,
                    event_date_id: this.event_date_id
                }
            })
                .then((res) => {
                    this.attendeesTotalCount = res.data.pagination.totalCount
                    this.attendeesTotalPages = res.data.pagination.totalPages
                    this.attendees = res.data.attendees
                    console.log(res.data)
                    this.length = this.attendees.length
                })
                .catch((err) => {
                    this.$error(err)
                })
                .finally(() => {
                    this.attendeesLoading = false;
                })
        },
        fetchPage(direction) {
            this.page += direction
            this.getAttendees()
        },
        async getSnedingTypes() {
            this.attendeesLoading = true;
            await this.$axios.get('/sending-types')
                .then((res) => {
                    this.sendingTypes = res.data
                    console.log(this.sendingTypes)
                })
                .catch((err) => {
                    this.$error(err)
                })
                .finally(() => {
                    this.attendeesLoading = false;
                })
        },
        getTicketStatus(type, error_logs) {
            if (!error_logs) return true
            if (type in error_logs) {
                return false
            }
            if ('Validation' in error_logs) {
                error_logs['Validation'].map((error) => {
                    if (error.startsWith(type)) {
                        return false
                    }
                })
            }
            return true
        },
        async searchFilter() {
            if (this.nameSearchValue || this.phoneSearchValue || this.emailSearchValue) {
                this.nameFilter = this.nameSearchValue
                this.phoneFilter = this.phoneSearchValue
                this.emailFilter = this.emailSearchValue
                this.page = 1
                await this.getAttendees()
            }
            else {
                await this.resetFilter()
            }
        },
        async resetFilter() {
            this.nameFilter = null
            this.phoneFilter = null
            this.emailFilter = null
            this.nameSearchValue = null
            this.phoneSearchValue = null
            this.emailSearchValue = null
            this.page = 1
            await this.getAttendees()
        },
        async downloadExcel() {
            try {
                this.excelLoading = true
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, [], 'QRally - Attendees');
                XLSX.utils.sheet_add_aoa(workbook.Sheets['QRally - Attendees'], [['UUID', 'Name', 'Email', 'Phone', 'Main Event', 'Ticket Status', 'Last Check-In']], { skipHeader: true, origin: -1 })
                this.nameFilter = null
                this.phoneFilter = null
                this.emailFilter = null
                this.nameSearchValue = null
                this.phoneSearchValue = null
                this.emailSearchValue = null

                for (let i = 1; i <= this.attendeesTotalPages; i++) {
                    try{

                        this.page = i
                        await this.getAttendees()
                        let data = []
                        this.attendees.map((attendee) => {
                            let final_obj = {}
                            final_obj['UUID'] = attendee.UUID
                            final_obj['Name'] = attendee.name
                            final_obj['Email'] = attendee.email
                            final_obj['Phone'] = attendee.phone
                            final_obj['Main Event'] = this.$filters.get_name(attendee.Event)
                            let ticket_status = ''
                            this.sendingTypes.map((type) => {
                                ticket_status += `${this.$filters.get_name(type)}: ${this.getTicketStatus(type, attendee.error_logs)}\n`
                            })
                            final_obj['Ticket Status'] = ticket_status
                            if (attendee.CheckIns.length){
                                final_obj['Last Check-In'] = `${this.$t('Date and Time')}: ${this.$filters.get_date_time(attendee.CheckIns[0].createdAt)}\n${this.$t('Event Name')}: ${this.$filters.get_name(attendee.CheckIns[0].EventDate)}\n${this.$t('Volunteer Name')}: ${attendee.CheckIns[0].CreatedBy.name}`
                            }
                            else {
                                final_obj['Last Check-In'] = this.$t('No Check-Ins')
                            }
    
                            data.push(final_obj)
                        })
    
                        const worksheet = XLSX.utils.json_to_sheet(data);
                        XLSX.utils.sheet_add_json(workbook.Sheets['QRally - Attendees'], XLSX.utils.sheet_to_json(worksheet), { skipHeader: true, origin: -1 });
                    }
                    catch (err) {
                        this.$error(err)
                        this.excelLoading = false
                        break
                    }
                }


                const blob = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    bookSST: false,
                    type: 'array',
                });

                const excelBlob = new Blob([blob], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });

                const url = window.URL.createObjectURL(excelBlob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${this.$t('Attendees')}(${this.$filters.get_date(new Date())}).xlsx`;
                link.click();
                window.URL.revokeObjectURL(url);
                this.resetFilter()
                this.excelLoading = false
            } catch (err) {
                this.$error(err)
                this.excelLoading = false
            }
        },
    }
}
</script>
