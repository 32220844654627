<template>
    <div>
        <v-data-table :items="eventsDates" :headers="headers" :items-length="eventsLength"
            :loading="eventsDatesLoading">
            <template v-slot:[`item.name`]="{ item }">
                <span>{{ $filters.get_name(item) }}</span>
            </template>
            <template v-slot:[`item.Event.name`]="{ item }">
                <div class="d-flex align-center flex-column my-2" style="row-gap: 6px">
                    <div
                        style="max-width: 100px;width: 100%; max-height: 100px;height: 100%;margin: 4px;padding: 1px;border: 1px solid gray;border-radius: 5px;">
                        <v-img :src="item.Event.logo ? item.Event.logo : require('../assets/default-event-photo.png')"
                            cover style="height: 100%;width: 100%;" />
                    </div>
                    <span>{{ $filters.get_name(item.Event) }}</span>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <div>
                    <div class="d-flex" style="column-gap: 5px;">
                        <span class="font-weight-bold">{{ $t('Date') }}:</span>
                        {{ $filters.get_date(item.date) }}
                    </div>
                    <div class="d-flex" style="column-gap: 5px;">
                        <span class="font-weight-bold">{{ $t('Time') }}:</span>
                        {{ $filters.get_time(item.date) }}
                    </div>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex align-center" style="column-gap: 15px;">
                    <v-btn :disabled="isDisabled(item.date)" color="black" @click="$router.push(`/scan/${item.id}`)">{{
            $t('Scan QR') }}</v-btn>
                    <v-btn :disabled="user.roleId != 7" color="black" @click="$router.push(`/check-ins/${item.id}`)">{{ $t('Check-Ins') }}</v-btn>
                    <v-btn :disabled="user.roleId != 7" color="black" @click="$router.push(`/attendees-per-event/${item.id}`)">{{ $t('Attendees') }}</v-btn>
                </div>
            </template>
            <template #bottom></template>
        </v-data-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'EventsTable',
    props: {

    },
    data: () => ({
        eventsDatesLoading: false,
        eventsDates: [],
        eventsLength: 0
    }),
    async created() {
        await this.getEventsDates();
    },
    computed: {
        ...mapGetters({
            user: 'user'
        }),
        headers() {
            return [
                {
                    title: this.$t('id'),
                    align: 'center',
                    sortable: false,
                    key: 'id',
                    sortable: true
                },
                {
                    title: this.$t('Event Name'),
                    key: 'name',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('Main Event Name'),
                    key: 'Event.name',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('Date and Time'),
                    key: 'date',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('Actions'),
                    key: 'actions',
                    align: 'center',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        async getEventsDates() {
            this.eventsDatesLoading = true;
            await this.$axios.get('/events/dates', {
                params: {
                    includeEvent: true
                }
            })
                .then((res) => {
                    this.eventsDates = res.data
                    this.eventsLength = this.eventsDates.length
                    console.log(this.eventsDates)
                })
                .catch((err) => {
                    this.$error(err)
                })
                .finally(() => {
                    this.eventsDatesLoading = false;
                })
        },
        isDisabled(event_date) {
            const todayStart = new Date(event_date);
            todayStart.setUTCHours(todayStart.getHours() - 5, 0, 0, 0);

            const todayEnd = new Date(event_date);
            todayEnd.setUTCHours(23, 59, 59, 999);

            const currentDate = new Date();
            if (!(currentDate >= todayStart && currentDate <= todayEnd)) {
                return true;
            }
            return false;
        }
    }
}
</script>
