export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل دخول"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل خروج"])},
  "Username or Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم أو البريد الالكتروني"])},
  "This field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل مطلوب"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "Password must be at least 8 characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تكون أكثر من 8 رموز"])},
  "Password must contain an uppercase letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تحتوي على أحرف كبيرة"])},
  "Password must contain an lowercase letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تحتوي على أحرف صغيرة"])},
  "Password must contain a digit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تحتوي على رقم"])},
  "Password must contain a special character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تحتوي على رمز خاص"])},
  "Wrong Credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات خاطئة"])},
  "Enter Email or Username and Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل البريد الالكتروني أو اسم المستخدم وكلمة المرور"])},
  "Something Went Wrong!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ ما!"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
  "QRally Ticketing System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام التذاكر"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
  "Name is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم المطلوب"])},
  "Email is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني مطلوب"])},
  "Email must be valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بريد صحيح"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرسل"])},
  "Enter more than 1 letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل أكثر من حرف"])},
  "Page number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة رقم"])},
  "Pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحات"])},
  "Hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهلًا"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإدارة"])},
  "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفعاليات"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم التعريفي"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفعال"])},
  "Main Event Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفعالية الرئيسية"])},
  "Event Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفعالية"])},
  "Date and Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت والتاريخ"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الساعة"])},
  "Scan QR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح QR"])},
  "Make sure that you have one QR code in the frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكد أن الكاميرا تستطيع رؤية كود QR واحد ليس أكثر"])},
  "No attendee id provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد رقم تعريفي للزائر"])},
  "No attendee with such id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم تعريفي خاطئ للزائر"])},
  "No event date with such id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم تعريفي خاطئ للفعالية"])},
  "The ticket has been scaned perviously": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم مسح هذه التذكرة سابقاً"])},
  "Check-in Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تسجيل الدخول بنجاح"])},
  "Current date is not within the event date range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يأتي وقت الدخول بعد"])},
  "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرات"])},
  "Attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزوار"])},
  "Total Attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الزوار الكلي"])},
  "Total Pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الصفحات الكلي"])},
  "Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة رقم"])},
  "Ticket Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع التذكرة"])},
  "Last Check-In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر تسجيل دخول"])},
  "Volunteer Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المتطوع"])},
  "No Check-Ins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد تسجيلات دخول"])},
  "Enter Attendee Name...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل اسم الزائر..."])},
  "Enter Attendee Phone Number...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم هاتف الزائر..."])},
  "Enter Attendee Email...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل إيميل الزائر..."])},
  "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ضبط"])},
  "Download Excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل إكسل"])},
  "Check-Ins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيلات الدخول"])},
  "Total Check-Ins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد تسجيلات الدخول الكلي"])},
  "Check-In Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت تسجيل الدخول"])},
  "Choose Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر فعالية"])}
}