<template>
  <div class="footer">
    <span>All Rights Reserved 2024 © QRally</span>
    <span>Powered by DoubleH Solutions</span>
  </div>
</template>

<script>

export default {
  name: 'TheFooter', 
}
</script>
<style scoped>
.footer{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(var(--v-theme-primary-darken-1));
  color: white;
  height: 122px;
  align-items: center;
  flex-direction: column;
}
</style>
