<template>
    <div class="pa-6">
        <div class="d-flex align-center justify-space-around" style="column-gap: 15px;">
            <v-text-field class="mt-4" prepend-inner-icon="mdi-magnify" variant="solo"
                :label="$t('Enter Attendee Name...')" v-model="nameSearchValue" @keyup.enter="searchFilter" />
            <v-text-field class="mt-4" prepend-inner-icon="mdi-phone" variant="solo"
                :label="$t('Enter Attendee Phone Number...')" v-model="phoneSearchValue" @keyup.enter="searchFilter" />
            <v-text-field class="mt-4" prepend-inner-icon="mdi-email" variant="solo"
                :label="$t('Enter Attendee Email...')" v-model="emailSearchValue" @keyup.enter="searchFilter" />
            <div class="d-flex flex-column" style="row-gap: 10px;">
                <v-btn class="font-weight-bold" color="info" @click="searchFilter">{{
                    $t('Search') }}</v-btn>
                <v-btn class="font-weight-bold" :disabled="!nameSearchValue && !phoneSearchValue && !emailSearchValue"
                    color="red" @click="resetFilter">{{ $t('Reset') }}</v-btn>
                <v-btn :loading="excelLoading" class="font-weight-bold" color="green" @click="downloadExcel">{{
                    $t('Download Excel')
                }}</v-btn>
            </div>
        </div>
        <div class="d-flex align-center justify-space-around my-5 flex-column flex-md-row"
            :style="$vuetify.display.mobile ? 'row-gap: 10px' : ''">
            <v-btn :size="$vuetify.display.mobile ? 'small' : 'default'" :disabled="page == 1" @click="fetchPage(-1)"
                :elevation="0" icon>
                <v-icon>{{ $i18n.locale === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
            </v-btn>

            <div>
                <div class="d-flex" style="column-gap: 6px;">
                    <span style="font-weight: 700;">{{ $t('Total Check-Ins') }}:</span>
                    <span>{{ checkInsTotalCount }}</span>
                </div>
                <div class="d-flex" style="column-gap: 6px;">
                    <span style="font-weight: 700;">{{ $t('Total Pages') }}:</span>
                    <span>{{ checkInsTotalPages }}</span>
                </div>
            </div>

            <div>
                <div class="d-flex" style="column-gap: 6px;">
                    <span style="font-weight: 700;">{{ $t('Page') }}:</span>
                    <span>{{ page }}</span>
                </div>
            </div>

            <v-btn :size="$vuetify.display.mobile ? 'small' : 'default'" :disabled="page == checkInsTotalPages"
                @click="fetchPage(1)" :elevation="0" icon>
                <v-icon>{{ $i18n.locale === 'ar' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
            </v-btn>
        </div>
        <v-data-table :items="checkIns" :headers="headers" :loading="checkInsLoading" dense :items-per-page="pageSize">
            <template #bottom>
                <div class="d-flex align-center justify-space-around mt-5">
                    <v-btn :size="$vuetify.display.mobile ? 'small' : 'default'" :disabled="page == 1"
                        @click="fetchPage(-1)" :elevation="0" icon>
                        <v-icon>{{ $i18n.locale === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                    </v-btn>

                    <v-btn :size="$vuetify.display.mobile ? 'small' : 'default'" :disabled="page == checkInsTotalPages"
                        @click="fetchPage(1)" :elevation="0" icon>
                        <v-icon>{{ $i18n.locale === 'ar' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                <div class="d-flex flex-column align-center">
                    <span>{{ $filters.get_date(item.createdAt) }}</span>
                    <span>{{ $filters.get_time(item.createdAt) }}</span>
                </div>
            </template>
            <template v-slot:[`item.EventDate.name`]="{ item }">
                {{ formatEventName(item.EventDate) }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
    name: 'CheckInsTable',
    props: {

    },
    data() {
        return {
            checkInsLoading: false,
            excelLoading: false,
            checkIns: [],
            page: 1,
            pageSize: 50,
            nameFilter: null,
            checkInsTotalCount: null,
            checkInsTotalPages: null,
            sendingTypes: [],
            nameSearchValue: null,
            phoneSearchValue: null,
            emailSearchValue: null,
            eventsDatesLoading: false,
            eventsDates: [],
            event_date_id: this.$route.params.event_date_id
        };
    },
    async created() {
        await this.getCheckIns();
        await this.getEventsDates();
    },
    computed: {
        headers() {
            return [
                {
                    title: this.$t('Name'),
                    key: 'Attendee.name',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Email'),
                    key: 'Attendee.email',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Phone Number'),
                    key: 'Attendee.phone',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Event Name'),
                    key: 'EventDate.name',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Check-In Time'),
                    key: 'createdAt',
                    align: 'center',
                    sortable: false
                },
                {
                    title: this.$t('Volunteer Name'),
                    key: 'CreatedBy.name',
                    align: 'center',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        async getCheckIns() {
            this.checkInsLoading = true;
            await this.$axios.get('/check-in', {
                params: {
                    page: this.page,
                    pageSize: this.pageSize,
                    name: this.nameFilter,
                    phone: this.phoneFilter,
                    email: this.emailFilter,
                    eventDateId: this.event_date_id,
                    includeEvent: true,
                    includeCreatedBy: true,
                    includeAttendee: true
                }
            })
                .then((res) => {
                    this.checkInsTotalCount = res.data.pagination.totalCount
                    this.checkInsTotalPages = res.data.pagination.totalPages
                    this.checkIns = res.data.checkIns
                    console.log(res.data)
                })
                .catch((err) => {
                    this.$error(err)
                })
                .finally(() => {
                    this.checkInsLoading = false;
                })
        },
        fetchPage(direction) {
            this.page += direction
            this.getCheckIns()
        },
        async searchFilter() {
            if (this.nameSearchValue || this.phoneSearchValue || this.emailSearchValue) {
                this.nameFilter = this.nameSearchValue
                this.phoneFilter = this.phoneSearchValue
                this.emailFilter = this.emailSearchValue
                // Event Id
                this.page = 1
                await this.getCheckIns()
            }
            else {
                await this.resetFilter()
            }
        },
        async resetFilter() {
            this.nameFilter = null
            this.phoneFilter = null
            this.emailFilter = null
            this.nameSearchValue = null
            this.phoneSearchValue = null
            this.emailSearchValue = null
            this.page = 1
            await this.getCheckIns()
        },
        formatEventName(item) {
            return `${this.$filters.get_name(item)} - ${this.$filters.get_name(item.Event)}`
        },
        async getEventsDates() {
            this.eventsDatesLoading = true;
            await this.$axios.get('/events/dates', {
                params: {
                    includeEvent: true
                }
            })
                .then((res) => {
                    this.eventsDates = res.data
                    console.log(this.eventsDates)
                })
                .catch((err) => {
                    this.$error(err)
                })
                .finally(() => {
                    this.eventsDatesLoading = false;
                })
        },
        async downloadExcel() {
            try {
                this.excelLoading = true

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, [], 'QRally - CheckIns');
                XLSX.utils.sheet_add_aoa(workbook.Sheets['QRally - CheckIns'], [['UUID', 'Name', 'Email', 'Phone', 'Main Event', 'Ticket Status', 'Last Check-In']], { skipHeader: true, origin: -1 })
                this.nameFilter = null
                this.phoneFilter = null
                this.emailFilter = null
                this.nameSearchValue = null
                this.phoneSearchValue = null
                this.emailSearchValue = null

                for (let i = 1; i <= this.checkInsTotalPages; i++) {
                    try {

                        this.page = i
                        await this.getCheckIns()
                        let data = []
                        this.checkIns.map((checkIn) => {
                            let final_obj = {}
                            final_obj['id'] = checkIn.id
                            final_obj['UUID'] = checkIn.Attendee.UUID
                            final_obj['Name'] = checkIn.Attendee.name
                            final_obj['Email'] = checkIn.Attendee.email
                            final_obj['Phone'] = checkIn.Attendee.phone
                            final_obj['Event'] = this.formatEventName(checkIn.EventDate)
                            final_obj['Check-In Time'] = this.$filters.get_date_time(checkIn.createdAt)
                            final_obj['Check-In By'] = checkIn.CreatedBy.name
                            data.push(final_obj)
                        })

                        const worksheet = XLSX.utils.json_to_sheet(data);
                        XLSX.utils.sheet_add_json(workbook.Sheets['QRally - CheckIns'], XLSX.utils.sheet_to_json(worksheet), { skipHeader: true, origin: -1 });
                    }
                    catch (err) {
                        this.$error(err)
                        this.excelLoading = false
                        break
                    }
                }


                const blob = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    bookSST: false,
                    type: 'array',
                });

                const excelBlob = new Blob([blob], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });

                const url = window.URL.createObjectURL(excelBlob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${this.$t('Check-Ins')}(${this.$filters.get_date(new Date())}).xlsx`;
                link.click();
                window.URL.revokeObjectURL(url);
                this.resetFilter()
                this.excelLoading = false
            } catch (err) {
                this.$error(err)
                this.excelLoading = false
            }
        },
    }
}
</script>
