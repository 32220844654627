<template>
  <AttendeesPerEventDateTable />
</template>

<script>

import AttendeesPerEventDateTable from '@/components/AttendeesPerEventDateTable.vue';
export default {
  name: 'AttendeesPerEventDatePage.vue',
  components:{
    AttendeesPerEventDateTable
  }
}
</script>
