<template>
  <EventsTable />
</template>

<script>

import EventsTable from '@/components/EventsTable.vue';
export default {
  name: 'EventsPage',
  components:{
    EventsTable
  }
}
</script>
