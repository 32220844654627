<template>
  <AttendeesTable />
</template>

<script>

import AttendeesTable from '@/components/AttendeesTable.vue';
export default {
  name: 'AttendeesPage',
  components:{
    AttendeesTable
  }
}
</script>
