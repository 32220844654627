<template>
  <v-container class="ma-0" style="min-width: 100%;">
    <v-row v-if="eventDate" justify="center" align="center">
      <v-col cols="8" md="2">
        <div
          style="max-width: 300px;width: 100%; max-height: 300px;height: 100%;margin: 4px;padding: 1px;border: 1px solid gray;border-radius: 5px;">
          <v-img :src="eventDate.Event.logo ? eventDate.Event.logo : require('../assets/default-event-photo.png')" cover
            style="height: 100%;width: 100%;" />
        </div>
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-center" style="font-size: larger;">
        <span>{{ `${$filters.get_name(eventDate)} - ${$filters.get_name(eventDate.Event)}` }}</span>
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-center" style="font-size: larger;">
        <span>{{ current_time }}</span>
      </v-col>
      <v-col md="6" cols="11" style="border: 1px solid gray;border-radius: 5px;padding: 2px;" class="my-5">
        <qrcode-stream @detect="onDetectQR"></qrcode-stream>
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center">
      <v-progress-circular size="90" indeterminate color="primary" />
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="showDialog" max-width="500">
        <v-card v-if="checkInLoading" style="padding:20px">
          <div class="d-flex align-center justify-center" style="width: 100%;height: 100%;">
            <v-progress-circular size="90" indeterminate color="primary" />
          </div>
        </v-card>
        <v-card v-else :style="this.$i18n.locale === 'ar' ? 'direction: rtl !important;' : ''"
          style="font-family: 'Almarai' !important;padding: 20px;">
          <v-card-title class="text-h5 text-center" style="font-family: 'Almarai' !important;">
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text class="align-center d-flex flex-column justify-center v-card-text text-center text-md-start" style="row-gap: 30px;">
            <span>{{ dialogMessage }}</span>
            <span style="color: red;font-weight: 700;">{{ dialogSecondMessage }}</span>
            <v-icon size="100" :color="dialogIconColor">
              mdi-{{ dialogIcon }}
            </v-icon>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDialog()">
              <span style="font-weight: 700;">{{ $t('Close') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  name: 'ScanPage',
  components: {
    QrcodeStream
  },
  data() {
    return {
      event_date_id: this.$route.params.event_date_id,
      eventDateLoading: false,
      eventDate: null,
      current_time: null,
      showDialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogIcon: '',
      dialogIconColor: '',
      checkInLoading: false,
      dialogSecondMessage: null
    };
  },
  async created() {
    this.current_time = this.$filters.get_current_time()
    setInterval(() => {
      this.current_time = this.$filters.get_current_time()
    }, 1000);
    await this.getEventDateById()
  },
  methods: {
    async getEventDateById() {
      this.eventDateLoading = true;
      await this.$axios.get(`/events/dates/${this.event_date_id}`, {
        params: {
          includeEvent: true
        }
      })
        .then((res) => {
          this.eventDate = res.data
          console.log(this.eventDate)
        })
        .catch((err) => {
          this.$error(err)
        })
        .finally(() => {
          this.eventDateLoading = false;
        })
    },
    closeDialog() {
      this.showDialog = false
      this.dialogIcon = ""
      this.dialogIconColor = ""
      this.dialogTitle = ""
      this.dialogMessage = ""
      this.dialogSecondMessage = null
    },
    openDialog(title='', message='', icon='', iconColor='', dialogSecondMessage=null) {
      this.dialogTitle = this.$t(title)
      this.dialogMessage = this.$t(message)
      this.dialogSecondMessage = dialogSecondMessage ? this.$t(dialogSecondMessage) : null
      this.dialogIcon = icon
      this.dialogIconColor = iconColor
      this.showDialog = true
    },
    async onDetectQR(detectedCodes) {
      if (detectedCodes.length > 1) {
        this.openDialog('Something Went Wrong!', 'Make sure that you have one QR code in the frame', 'close-thick', 'red')
      }
      let detectedCode = detectedCodes[0]
      let raw_value = detectedCode.rawValue
      this.checkInLoading = true;
      this.showDialog = true
      await this.$axios.post('/check-in', {
        event_date_id: this.event_date_id,
        attendee_id: raw_value
      })
        .then((res) => {
          console.log(res)
          if(res.data && res.data.data && res.data.data.attendee.UUID){
            if(res.data.data.check_ins) this.openDialog('Check-in Successfully', `${this.$t('Name')}: ${res.data.data.attendee.name}`, 'car-brake-alert', 'orange', `${this.$t('The ticket has been scaned perviously')}: ${res.data.data.check_ins} ${this.$t('times')}`)
            else this.openDialog('Check-in Successfully', `${this.$t('Name')}: ${res.data.data.attendee.name}`, 'check', 'green')
          }
          else{
            this.openDialog('Something Went Wrong!', res.data ? res.data.data : 'Something Went Wrong!', 'close-thick', 'red')
          }
        })
        .catch((err) => {
          this.$error(err)
        })
        .finally(() => {
          this.checkInLoading = false;
        })
      }
  }
}
</script>
