<template>
  <CheckInsTable />
</template>

<script>

import CheckInsTable from '@/components/CheckInsTable.vue';
export default {
  name: 'CheckInsPage',
  components:{
    CheckInsTable
  }
}
</script>
