import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './src/views/LoginPage.vue'
import EventsPage from './src/views/EventsPage.vue'
import ScanPage from './src/views/ScanPage.vue'
import AttendeesPage from './src/views/AttendeesPage.vue'
import CheckInsPage from './src/views/CheckInsPage.vue'
import AttendeesPerEventDatePage from './src/views/AttendeesPerEventDatePage.vue'
import { isAdmin, isGuest, isLoggedin } from './middleware'
import i18n from './src/i18n'

const routes = [
  {
    path: '/',
    redirect: () => {
      if (isLoggedin) {
        return '/events';
      } else {
        return '/login';
      }
    }
  },
  {
    path: '/events',
    name: 'EventsPage',
    component: EventsPage,
    meta: { title: `${i18n.global.t('Events')} - ${i18n.global.t('QRally Ticketing System')}`, middleware: isLoggedin }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: { title: `${i18n.global.t('Login')} - ${i18n.global.t('QRally Ticketing System')}`, middleware: isGuest }
  },
  {
    path: '/scan/:event_date_id',
    name: 'ScanPage',
    component: ScanPage,
    meta: { title: `${i18n.global.t('Scan QR')} - ${i18n.global.t('QRally Ticketing System')}`, middleware: isLoggedin }
  },
  {
    path: '/attendees',
    name: 'AttendeesPage',
    component: AttendeesPage,
    meta: { title: `${i18n.global.t('Attendees')} - ${i18n.global.t('QRally Ticketing System')}`, middleware: isLoggedin }
  },
  {
    path: '/check-ins/:event_date_id',
    name: 'CheckInsPage',
    component: CheckInsPage,
    meta: { title: `${i18n.global.t('Check-Ins')} - ${i18n.global.t('QRally Ticketing System')}`, middleware: isAdmin }
  },
  {
    path: '/attendees-per-event/:event_date_id',
    name: 'AttendeesPerEventDatePage',
    component: AttendeesPerEventDatePage,
    meta: { title: `${i18n.global.t('Attendees')} - ${i18n.global.t('QRally Ticketing System')}`, middleware: isAdmin }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/login',
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ||'QRally';
  if (to.meta.middleware) {
    to.meta.middleware({ to, from, next });
  }
  next();
});

export default router;